<template>
  <div>
    <Header title="Dades de la signatura"></Header>
    <div class="container">
      <div class="box">
        <p class="review-text">Revisa les teves dades abans de continuar amb la signatura</p>
        <div class="signature-summary" v-if="signatureData">
          <div class="signature-summary-row">
            <label>Nom</label>
            <div>{{ signatureData.signer.name }}</div>
          </div>
          <div class="signature-summary-row">
            <label>{{ signatureData.signer.id_doc_type }}</label>
            <div>{{ signatureData.signer.id_doc }}</div>
          </div>
          <div class="signature-summary-row">
            <label>Email</label>
            <div>{{ signatureData.signer.email }}</div>
          </div>
          <div class="signature-summary-row">
            <label>Telèfon</label>
            <div>{{ signatureData.signer.pretty_phone }}</div>
          </div>
          <div class="signature-summary-row" v-if="signatureData.signer.address">
            <label>Adreça</label>
            <div>{{ signatureData.signer.address }}</div>
          </div>
          <div class="signature-summary-row">
            <label>Documents a signar</label>
            <div><p v-for="doc of signatureData.documents" :key="doc.name">{{ doc.filename }}</p></div>
          </div>
        </div>
        <div class="step-actions">
          <div>
            <button class="btn btn-primary" @click="next">Continuar <i class="las la-arrow-right"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/includes/Header'
import tracker from '@/mixins/tracker'

export default {
  name: 'signer-data',
  props: ['signatureData'],
  mixins: [tracker],
  components: {
    Header
  },
  methods: {
    next () {
      this.track('reviewed-personal-data')
      this.$emit('next')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables";

.signature-summary {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  text-align: left;
  .signature-summary-row {
    display: flex;
    margin-bottom: 15px;
    label {
      flex: 1;
      color: $primary-color;
      min-width: 50%;
    }
    div {
      flex: 1;
      word-break: break-word;
    }
  }
}
.review-text {
  margin-bottom: 30px;
}
.box {
  padding: 20px;
}
</style>
