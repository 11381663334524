import axios from 'axios'
import btc from '@/mixins/base-tenant-component'

export default {
  mixins: [btc],
  methods: {
    track (event, modelId) {
      axios.post(this.tenantUrl('/signature-requests/' + this.$route.params.uuid + '/track'), {
        event,
        model_id: modelId
      })
    }
  }
}
