<template>
  <div v-loading="loading">
    <signer-data :signature-data="signatureData" v-if="step === 1" @next="setStep(2)"></signer-data>
    <documents :signature-data="signatureData" v-if="step === 2" @back="setStep(1)" @next="setStep(3)"></documents>
    <o-t-p-signature :signature-data="signatureData" v-if="step === 3" @back="setStep(2)" @next="setStep(4)"></o-t-p-signature>
  </div>
</template>

<script>
import axios from 'axios'
import btc from '@/mixins/base-tenant-component'
import SignerData from './includes/SignerData'
import Documents from './includes/Documents'
import OTPSignature from './OTPSignature'

export default {
  name: 'sign',
  mixins: [btc],
  components: {
    SignerData,
    Documents,
    OTPSignature
  },
  data () {
    return {
      signatureData: null,
      loading: false,
      step: 1
    }
  },
  mounted () {
    this.getSignatureData()
  },
  methods: {
    getSignatureData () {
      this.loading = true
      axios.get(this.tenantUrl('/signature-requests/' + this.$route.params.uuid)).then((resp) => {
        this.signatureData = resp.data
      }).catch((e) => {
        console.error(e)
      }).finally(() => {
        this.loading = false
      })
    },
    setStep (stepNum) {
      if (stepNum < 1 || stepNum > 3) return
      this.step = stepNum
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
