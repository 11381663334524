<template>
  <div>
    <div class="container">
      <h1>Política de privacitat</h1>
      <div class="box">
        <p>Mitjan&ccedil;ant el present av&iacute;s, ONCLICK SOLUCIONS, S.L. (d&apos;ara en endavant ONCLICK SOLUCIONS) informa als usuaris del lloc sign.stratya.com, en compliment de l&apos;article 5 de la Llei Org&agrave;nica 15/1999 de Protecci&oacute; de Dades de Car&agrave;cter Personal (d&apos;ara en endavant LOPD) sobre la seva pol&iacute;tica de protecci&oacute; de dades personals, amb la finalitat que aquests decideixin de forma expressa, lliure i volunt&agrave;ria, si desitgen facilitar les dades personals que els hi siguin sol&middot;licitades a la web per a la prestaci&oacute; dels seus serveis.</p>
        <p><br></p>
        <p>Tamb&eacute; s&apos;informa als usuaris de la incorporaci&oacute; de les dades a fitxers, el titular y responsable dels quals &eacute;s ONCLICK SOLUCIONS, duent a terme un tractament informatitzat amb la finalitat de processar la sol&middot;licitut i, en el seu cas, remetre-li informaci&oacute; sobre les activitats de ONCLICK SOLUCIONS que puguin resultar del seu inter&egrave;s, pel qual ONCLICK SOLUCIONS sol&middot;licita el seu consentiment, incloses les comunicacions enviades per sistemes de trucades autom&agrave;tiques mitjan&ccedil;ant serveis de telecomunicacions sense intervenci&oacute; humana: missatges SMS i correu electr&ograve;nic.</p>
        <p><br></p>
        <p>Llevat que s&apos;especifiqui el contrari, es considerar&agrave; necessari completar totes les dades requerides en els formularis. En el cas de no facilitar totes les dades estimades com a necess&agrave;ries, ONCLICK SOLUCIONS podr&agrave;, depenent del cas, no cursar la concreta sol&middot;licitut que s&apos;hagi efectuat.</p>
        <p><br></p>
        <p>L&apos;usuari haur&agrave; d&apos;omplir els formularis amb dades verdaderes, exactes, completes i actualitzades, responent dels danys i prejudicis que es pogu&eacute;s ocasionar a causa de la complementaci&oacute; defectuosa dels formularis amb dades falses, inexactes, incompletes o no actualitzades.</p>
        <p><br></p>
        <p>ONCLICK SOLUCIONS ha adoptat els nivells de seguretat de protecci&oacute; de les dades personals exigides en la legislaci&oacute; vigent de protecci&oacute; de dades, instal&middot;lant les mesures t&egrave;cniques i organitzatives necessaris per evitar la p&egrave;rdua, mal us, alteraci&oacute;, acc&eacute;s no autoritzat i m&eacute;s riscs possibles.</p>
        <p><br></p>
        <p>De la mateixa manera, ONCLICK SOLUCIONS es compromet al compliment de la seva obligaci&oacute; de secret de les dades personals i del seu deure de guardar-los, i adoptar&agrave; totes les mesures necess&agrave;ries per evitar l&apos;alteraci&oacute;, p&egrave;rdua, tractament &ograve; acc&eacute;s no autoritzat, degudament en tot moment del estat de la tecnologia.</p>
        <p><br></p>
        <p>L&apos;usuari o persona que ho representi podr&agrave; exercir en qualsevol moment el dret d&apos;acc&eacute;s, rectificaci&oacute;, cancel&middot;laci&oacute; i, en el seu cas, oposici&oacute; d&apos;acord en l&apos;establert en la LOPD i altres normatives aplicables a tal efecte, dirigint una comunicaci&oacute; escrita i/&ograve; electr&ograve;nica a la direcci&oacute; info@on-click.es o a ONCLICK SOLUCIONS, amb direcci&oacute; a Carrer Manresa 14, Poligon industrial Mas Beul&oacute;, 08500 Vic Barcelona, Espanya, on s&apos;acrediti l&apos;identitat de l&apos;usuari.</p>
        <p><br></p>
        <p>En cas de que l&apos;empresa prest&eacute;s algun tipus de servei especial on es determini unes previsions espec&iacute;fiques diferents a aquestes, en el relacionat a la protecci&oacute; de dades, tindr&agrave; prioritat l&apos;aplicaci&oacute; de les normes particulars indicades per a aquest servei en particular per damunt de les presents, en cas d&apos;incongru&egrave;ncia.</p>
        <p><br></p>
        <p>ONCLICK SOLUCIONS es reserva el dret a modificar la present pol&iacute;tica, informant pr&egrave;viament als usuaris dels canvis que es vagin introduint.</p>
        <p><br></p>
        <p>BAIXA Si no desitja rebre m&eacute;s correus electr&ograve;nics comercials pot sol&middot;licitar-ho per e-mail a la direcci&oacute; info@on-click.es</p>
        <p><br></p>
        <p>Dades relatives als propis clients</p>
        <p>La LOPD estableix garanties de protecci&oacute; de les dades personals dels clientes/usuaris dels serveis prestats per ONCLICK SOLUCIONS. Les dades personals que recull s&oacute;n les necess&agrave;ries per la prestaci&oacute; dels serveis, la facturaci&oacute; i gesti&oacute; administrativa, tals com i a efectes de numeraci&oacute;: nom i cognoms, DNI, direcci&oacute; postal, direcci&oacute; electr&ograve;nica, dades bancaries. En aquest sentit, ONCLICK SOLUCIONS garanteix als seus usuaris/clients que tractar&agrave; les seves dades personals d&apos;acord amb els seg&uuml;ents principis:</p>
        <p><br></p>
        <p><br></p>
        <p>-Informaci&oacute;: ONCLICK SOLUCIONS informar&agrave; als seus usuaris sobre el tractament de les seves dades personals en el moment de la formalitzaci&oacute; del contracte de prestaci&oacute; de serveis, indicant les finalitats del tractament esmentat, previsi&oacute; de cessi&oacute; dels mateixos i indicaci&oacute; de com exercir els drets d&apos;acc&eacute;s, rectificaci&oacute; i cancel&middot;laci&oacute;.</p>
        <p>-Qualitat: ONCLICK SOLUCIONS tractar&agrave; les seves dades solament d&apos;acord amb les finalitats inherents als serveis prestats i a la facturaci&oacute; dels mateixos, aix&iacute; com per a la remissi&oacute; d&apos;informaci&oacute; comercial dels serveis de ONCLICK SOLUCIONS. Aix&iacute; mateix, es prevaldr&agrave; que les dades recaptades siguin adequades, pertinents i no excessives en relaci&oacute; amb la finalitat per la qual van ser recaptats.</p>
        <p>-Comunicaci&oacute;: Les seves dades personals no seran cedides per part de ONCLICK SOLUCIONS sense el seu consentiment amb excepci&oacute; d&apos;aquells previstos per llei, ja sigui per requeriment de les autoritats o b&eacute; perqu&egrave; aquesta cessi&oacute; sigui necess&agrave;ria per al compliment de les seves obligacions d&apos;administraci&oacute; dels serveis.</p>
        <p>-Seguretat: Les mesures de seguretat exigides per llei, especialment el que disposa el Reial decret 1720/2007 pel qual s&apos;aprova el Reglament de Desenvolupament de la Llei Org&agrave;nica sobre Protecci&oacute; de Dades de Car&agrave;cter Personal, seran ateses per part de ONCLICK SOLUCIONS en el desenvolupament de la prestaci&oacute; dels seus serveis, amb especial atenci&oacute; a l&apos;acc&eacute;s als sistemes mitjan&ccedil;ant controls d&apos;acc&eacute;s, la delimitaci&oacute; d&apos;aquest acc&eacute;s en relaci&oacute; a compliment de les funcions del personal i l&apos;establiment de c&ograve;pies de seguretat i recuperaci&oacute;.</p>
      </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'privacy'
}
</script>

<style lang="scss" scoped>
.box {
  padding: 30px;
  text-align: left;
}
</style>
