<template>
  <div>
    <Header title="Confirmació de signatura"></Header>
    <div class="container">
      <div class="box">
        <img src="/img/signature-success.svg" />
        <h1>Has signat correctament el document/s</h1>
        <p>Rebràs un email amb el document signat.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/includes/Header'

export default {
  name: 'confirmation',
  components: {
    Header
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
img {
  width: 80%;
  max-width: 300px;
}
.box {
  padding: 30px;
}
</style>
