<template>
  <div>
    <Header :title="'Verificació de documents'"></Header>
    <div class="container" v-loading="loading">
      <div class="box" v-if="response === null">
        <p>Per verificar la validesa d'un document signat amb Stratya Sign, puja'l a continuació:</p>
        <div class="file-form-group">
          <label>Document a verificar</label>
          <input id="file" type="file">
          <div class="error-msg" v-if="errorMsg">{{ errorMsg }}</div>
        </div>
        <div>
          <button class="btn btn-primary" @click="verifyId">Verificar</button>
        </div>
      </div>
      <div class="box document-response" v-if="response !== null">
        <div v-if="response === false">
          <h1>La signatura del document no és vàlida</h1>
          <img src="/img/document-invalid.svg" />
          <p>El document no conté cap signatura vàlida o bé ha estat alterat</p>
        </div>
        <div v-if="response !== false">
          <h1>La signatura del document és vàlida</h1>
          <img src="/img/document-valid.svg" />
          <p>Aquestes són les dades relatives a la signatura:</p>
          <div>
            <div v-for="(item, i) of response" :key="i" class="item-data">
              <label>{{ item.label }}</label>
              <div>{{ item.value }}</div>
            </div>
          </div>
        </div>
        <button class="btn btn-secondary" @click="response = null">Verificar un altre document</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import btc from '@/mixins/base-tenant-component'
import Header from '@/components/includes/Header'

export default {
  name: 'verify',
  mixins: [btc],
  components: {
    Header
  },
  data () {
    return {
      id_doc: null,
      loading: false,
      errorMsg: null,
      response: null
    }
  },
  methods: {
    showError (message) {
      this.errorMsg = message
      console.log(message)
    },
    verifyId () {
      this.loading = true
      var formData = new FormData()
      var imagefile = document.querySelector('#file')
      formData.append('file', imagefile.files[0])
      axios.post(this.tenantUrl('/document/verify'), formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((resp) => {
        this.response = resp.data.message
        // if (resp.data === false) {
        //
        // } else {
        //
        // }
      }).catch((e) => {
        if (e.response.status === 422) {
          this.showError(e.response.data.errors.file[0])
        } else if (e.response.status === 401) {
          this.showError(e.response.data.error_message)
        } else {
          this.showError('S\'ha produït un error. Prova-ho més tard')
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.document-response {
  color: black;
  img {
    width: 200px;
    max-width: 90%;
  }
  .item-data {
    margin-bottom: 10px;
    width: 300px;
    margin: 0 auto 15px auto;
    max-width: 90%;
    text-align: center;
    label {
      text-transform: uppercase;
      color: #3d4fa6;
      font-weight: 300;
      padding-bottom: 3px;
      display: inline-block;
    }
  }
  &.box {
    padding: 20px;
  }
}
.box {
  padding-top: 15px;
}
.file-form-group {
  max-width: 500px;
  margin: 0 auto;
  padding-top: 15px;
  text-align: center;
  label {
    font-size: 19px;
    color: #3c4263;
    padding-bottom: 10px;
    display: block;
    font-weight: bold;
    text-align: center;
  }
  input {
    max-width: 500px;
    margin: 0 auto;
    padding-top: 15px;
    text-align: center;
  }
}
button {
  margin: 15px 0;
}
.error-msg {
  color: #de2f2f;
  margin-top: 10px;
  font-size: 14px;
}
.btn.btn-secondary {
  background: #d4d4d4;
  color: #1b1b1b;
}
</style>
