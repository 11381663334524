<template>
  <div>
    <Header :title="title">
      <template v-slot:left>
        <button class="btn-back" @click="back"><i class="las la-arrow-left"></i></button>
      </template>
    </Header>
    <div class="container">
      <div class="box">
        <pdf-viewer :pdf-path="signatureData.documents[currentDocumentIndex].url" :show-pdf-viewer="true"
                    @next="nextDocument"></pdf-viewer>
      </div>

    </div>
<!--    <div class="step-actions">-->
<!--      <div>-->
<!--        <button @click="$emit('back')">Tornar</button>-->
<!--      </div>-->
<!--      <div>-->
<!--        <button @click="$emit('next')">Continuar</button>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import PdfViewer from './PdfViewer'
import Header from '@/components/includes/Header'
import tracker from '@/mixins/tracker'

export default {
  name: 'documents',
  props: ['signatureData'],
  components: {
    PdfViewer,
    Header
  },
  mixins: [tracker],
  data () {
    return {
      currentDocumentIndex: 0
    }
  },
  computed: {
    numDocuments () {
      return this.signatureData.documents.length
    },
    title () {
      return 'Document ' + (this.currentDocumentIndex + 1) + ' de ' + this.numDocuments
    }
  },
  methods: {
    nextDocument () {
      this.track('readed-document', this.signatureData.documents[this.currentDocumentIndex].id)
      if (this.currentDocumentIndex === (this.numDocuments - 1)) {
        this.$emit('next')
      } else {
        this.currentDocumentIndex++
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      }
    },
    prevDocument () {
      this.currentDocumentIndex--
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },
    back () {
      if (this.currentDocumentIndex === 0) {
        this.$emit('back')
      } else {
        this.prevDocument()
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
