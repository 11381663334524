<template>
  <div>
    <Header title="Signatura del document">
      <template v-slot:left>
        <button class="btn-back" @click="$emit('back')"><i class="las la-arrow-left"></i></button>
      </template>
    </Header>
    <div class="container" v-loading="loading">
      <div class="box otp-container">
        <p>T'hem enviat un SMS amb el codi de signatura al {{ signatureData.signer.pretty_phone }}.</p>
        <otp-input
          class="otp-input-container"
          ref="otpInput"
          input-classes="otp-input"
          separator=""
          :num-inputs="6"
          :should-auto-focus="true"
          :is-input-num="true"
          @on-complete="handleOTPComplete"
        />
        <button class="btn btn-primary" @click="signDocuments" :disabled="otp.length !== 6"><i class="las la-check"></i> Signar document/s</button>
        <small class="legal-text">Al signar el document, acceptes la <router-link
          to="/privacy-policy"
          target="_blank"
        >Política de privacitat
        </router-link> i les <router-link
          to="/signature-policy"
          target="_blank"
        >Condicions de signatura
        </router-link>
        </small>
        <div v-if="canSendOTP">
          <button class="resend-sms" @click="generateOTP">Tornar a enviar SMS</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OtpInput from '@bachdgvn/vue-otp-input'
import btc from '@/mixins/base-tenant-component'
import axios from 'axios'
import Header from '@/components/includes/Header'

export default {
  name: 'otp-signature',
  props: ['signatureData'],
  components: {
    OtpInput,
    Header
  },
  mixins: [btc],
  data () {
    return {
      loading: true,
      otp: '',
      canSendOTP: false
    }
  },
  mounted () {
    this.generateOTP()
  },
  methods: {
    generateOTP () {
      this.loading = true
      this.canSendOTP = false
      axios.post(this.tenantUrl('/signature-requests/' + this.$route.params.uuid + '/request-sign')).catch((e) => {
        if (e.response.status === 404) {
          this.showError('No existeix cap firma pendent')
        } else if (e.response.status === 422) {
          this.showError(e.response.data.errors.id_doc[0])
        } else if (e.response.status === 403) {
          this.showError(e.response.data.error_message)
        } else {
          this.showError('S\'ha produït un error. Prova-ho més tard')
        }
      }).finally(() => {
        this.loading = false
        setTimeout(() => {
          this.canSendOTP = true
        }, 30000)
      })
    },
    handleOTPComplete (value) {
      this.otp = value
    },
    showError (message) {
      this.$confirm('', message, {
        confirmButtonText: "D'acord",
        showCancelButton: false,
        type: 'error',
        center: true
      }).then(() => {
        console.log('showError')
      })
    },
    signDocuments () {
      this.loading = true
      axios.post(this.tenantUrl('/signature-requests/' + this.$route.params.uuid + '/sign'), {
        otp_code: this.otp
      }).then(() => {
        this.$router.replace({ name: 'Confirmation', params: { tenant: this.$route.params.tenant, uuid: this.$route.params.uuid } })
      }).catch((e) => {
        if (e.response.status === 422) {
          this.showError(e.response.data.errors.otp_code[0])
        } else if (e.response.status === 403) {
          this.showError(e.response.data.error_message)
        } else {
          this.showError('S\'ha produït un error. Prova-ho més tard')
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.otp-container {
  padding: 25px;
  input{
    max-width: 45px;
    padding: 10px 5px;
    text-align: center;
    margin-left: 5px;
  }
  >div {
    align-items: center;
    justify-content: center;
  }
  .btn {
    margin: 20px 0;
  }
  .la-check {
    margin-right: 10px;
  }
}
.resend-sms {
  text-decoration: underline;
  background: transparent;
  cursor: pointer;
  border: none;
  &:hover {
    opacity: .8;
  }
}
.otp-input-container {
  padding: 20px 0;
}
.legal-text {
  display: block;
  color: gray;
  font-size: 11px;
  padding: 0px 0 25px 0;
  a {
    color: gray;
  }
}
</style>
