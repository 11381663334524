<template>
  <div>
    <div class="container">
      <h1>Condicions de signatura</h1>
      <div class="box">
        <h2>Com funciona?</h2>
        <p>El present document t&eacute; com a finalitat establir unes petites pautes de qu&egrave; &eacute;s una signatura electr&ograve;nica i com funciona.</p>
        <p>La signatura electr&ograve;nica, de conformitat amb com la defineix l&apos;article 3.10 de l&apos;Reglament &quot;EIDAS&quot;, s&oacute;n &quot;les dades en format electr&ograve;nic annexos a altres dades electr&ograve;niques o associats de manera l&ograve;gica amb ells que utilitza el signant a l&apos;hora de signar.&quot; La dita definici&oacute; &eacute;s molt &agrave;mplia, amb la finalitat de donar compliment a el principi de neutralitat tecnol&ograve;gica, tot i que no totes tenen la mateixa efic&agrave;cia jur&iacute;dica, fent distinci&oacute; entre la signatura electr&ograve;nica simple, l&apos;avan&ccedil;ada i la qualificada. Per tant, oblidem la imatge que ten&iacute;em fins ara de l&apos;gargot que f&egrave;iem per signar.</p>
        <p>La signatura electr&ograve;nica simple, al requerir de pocs elements de seguretat, no se li atorguen efectes jur&iacute;dics vinculants.</p>
        <p>La signatura electr&ograve;nica avan&ccedil;ada, si t&eacute; plena validesa jur&iacute;dica i per a aix&ograve; ha de reunir els seg&uuml;ents requisits:</p>
        <ul>
          <li>estar vinculada a l&apos;signant de manera &uacute;nica;</li>
          <li>permetre la identificaci&oacute; del signant;</li>
          <li>haver estat creada utilitzant dades de creaci&oacute; de la signatura electr&ograve;nica que el signant pot utilitzar, amb un alt nivell de confian&ccedil;a, sota el seu control exclusiu, i</li>
          <li>estar vinculada amb les dades signades per la mateixa de manera tal que qualsevol modificaci&oacute; posterior dels mateixos sigui detectable.c</li>
          <li>la signatura electr&ograve;nica qualificada, &eacute;s una firma avan&ccedil;ada que t&eacute; la mateixa efic&agrave;cia jur&iacute;dica que una manuscrita i que, a m&eacute;s de tenir els requisits d&apos;una signatura electr&ograve;nica avan&ccedil;ada, ha de:<ul>
            <li>ser creada per un dispositiu qualificat de creaci&oacute; de signatures electr&ograve;niques</li>
            <li>que es basa en un certificat qualificat de signatura electr&ograve;nica.</li>
          </ul>
          </li>
        </ul>
<!--        <p>STRATYA SIGN &eacute;s a la llista de &quot;Prestadores de servicios electr&oacute;nicos de confianza no cualificados.&quot; del Ministerio de Industria, Comercio y Turismo, tal com estableix la llei vigent.</p>-->
<!--        <p>STRATYA SIGN realitza signatures electr&ograve;niques avan&ccedil;ades no qualificades que serveixen per signar la majoria dels documents del nostre dia a dia:</p>-->
        <p>Factures, acreditaci&oacute; de vaig rebre de productes ...</p>
        <p>Contractes de distribuci&oacute; de productes</p>
        <p>Partes d&apos;assist&egrave;ncia</p>
        <p>Contractes de treball, Epis ....</p>
        <p>Documentaci&oacute; relativa a protecci&oacute; de dades</p>
        <p>Acceptaci&oacute; de pressupostos</p>
        <p>Partes d&apos;assist&egrave;ncia</p>
        <p>Consentiments informats</p>
        <p>i un llarg etc ...</p>
        <p>Qu&egrave; no es pot signar amb una signatura electr&ograve;nica avan&ccedil;ada?</p>
        <p>No es podran subscriure els contractes exclosos de la contractaci&oacute; electr&ograve;nica que estableix l&apos;article 9 de la directiva 2000/31 CE de Parlament europeu, que s&oacute;n:</p>
        <p>Els contractes de creaci&oacute; o transfer&egrave;ncia de drets en mat&egrave;ria immobili&agrave;ria, amb l&apos;excepci&oacute; dels drets d&apos;arrendament.</p>
        <p>Els contractes que requereixin per llei la intervenci&oacute; dels tribunals, les autoritats p&uacute;bliques o professionals que exerceixin una funci&oacute; p&uacute;blica.</p>
        <p>Els contractes de cr&egrave;dit i cauci&oacute; i les garanties presentades per persones que actuen per motius aliens a la seva activitat econ&ograve;mica, negoci o professi&oacute;.</p>
        <p>Els contractes en mat&egrave;ria de Dret de fam&iacute;lia o de successions.</p>
        <p>Aix&iacute; mateix, no es podran subscriure contractes o documentaci&oacute; que per les seves caracter&iacute;stiques, presents o futures, requereixin de signatura manuscrita o de formalitats espec&iacute;fiques i especialment, les subjectes a legislaci&oacute; internacional.</p>
        <p>Per aix&ograve; queden exclosos tots els documents que requereixin de notari, com la signatura d&apos;una hipoteca o la compravenda d&apos;un immoble. Aix&ograve; es deu a raons de seguretat jur&iacute;dica.</p>
        <p>Molts dels dubtes que ens sorgeixen a l&apos;hora de signar un document de forma electr&ograve;nica tenen a veure amb la seguretat jur&iacute;dica que se&apos;ns planteja i la por a la suplantaci&oacute;. Vegem com la legislaci&oacute; i STRATYA SIGN aborden aquests punts.</p>
        <p>S&apos;estableix com a requisit que la signatura ha d&apos;estar vinculada a l&apos;signant de manera &uacute;nica i permetre la identificaci&oacute; d&apos;ell mateix; &eacute;s a dir, que qui signa sigui jo. La signatura de el document des STRATYA SIGN es pot realitzar des del correu electr&ograve;nic, en el qual ens arribar&agrave; un enlla&ccedil; a la plataforma. Per a aix&ograve; cal haver accedit pr&egrave;viament a l&apos;correu. Un cop llegit el document, realitzarem la signatura, per la qual cosa se&apos;ns demanar&agrave; un codi anomenat OTP, que cal introduir en el moment just de la signatura. Per a aix&ograve;, cal tenir acc&eacute;s al nostre terminal m&ograve;bil. En el moment de la signatura i per mitj&agrave; d&apos;una s&egrave;rie d&apos;accions inform&agrave;tiques, el document es queda registrat amb una s&egrave;rie de &quot;metadades&quot;, que recullen en quin moment es va signar, des que IP, etc ... Aquesta informaci&oacute; queda validada per una clau p&uacute;blica, de manera que d&oacute;na la seguretat jur&iacute;dica necess&agrave;ria per a evitar falsificacions. Un cop signat i recopilat les metadades, es realitza una operaci&oacute; matem&agrave;tica que es diu &quot;hash&quot; que fa que qualsevol dada que s&apos;alterni de el document sigui descobert.</p>
        <h2>Què accepto al signar un document?</h2>
      Al signar un document, acceptes les següents condicions:
      <ul>
        <li>- STRATYA SIGN guardarà totes les dades relatives a la signatura, com són els documents firmats, dades que permetin relacionar el signant amb la signatura com els dispositius, xarxes des d'on accedeix, entre d'altres, i les diferents accions que ha realitzat durant el procés</li>
        <li>- STRATYA SIGN pot compartir aquesta informació als afectats per la signatura o a petició d'una autoritat judicial</li>
      </ul>
    </div></div>
  </div>
</template>

<script>
export default {
  name: 'privacy'
}
</script>

<style lang="scss" scoped>
.box {
  padding: 30px;
  text-align: left;
  line-height: 28px;
}
</style>
