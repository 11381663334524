<template>
  <div class='pdf-viewer-wrapper' :class='{"zoom-active": zoom > 100 }' v-loading.fullscreen.lock="loading" element-loading-text="Carregant PDF...">
    <pinch-zoom disable-zoom-control="disable" :wheel="false">
      <pdf
        :src="src"
        :page="currentPage"
        @num-pages="pageCount = $event"
        @page-loaded="handlePageLoaded"
        :style="{width: zoom + '%'}"
      >
        <template slot="loading">
          Carregant...
        </template>
      </pdf>
    </pinch-zoom>
    <div class="bottom-pdf-actions">
      <div class="container">
        <div class="current-page">
          {{ currentPage }} / {{ pageCount }}
        </div>
        <div class="buttons">
          <button class="btn" v-if="!noPrevPage" @click="prevPage" :disabled="loading"><i class="las la-arrow-left"></i></button>
          <button class="btn btn-primary" v-if="!noNextPage" @click="nextPage" :disabled="loading">Següent pàgina <i class="las la-arrow-right"></i></button>
          <button class="btn btn-primary" v-if="noNextPage" @click="$emit('next')">Continuar <i class="las la-arrow-right"></i></button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import pdf from 'vue-pdf'
import PinchZoom from 'vue-pinch-zoom'

export default {
  name: 'PdfViewer',
  props: ['pdfPath', 'showPdfViewer'],
  components: {
    pdf,
    PinchZoom
  },
  watch: {
    pdfPath: function (newVal, oldVal) {
      this.currentPage = 1
      this.src = pdf.createLoadingTask(newVal)
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    }
  },
  mounted () {
    this.src = pdf.createLoadingTask(this.$props.pdfPath)
  },
  data () {
    return {
      currentPage: 1,
      pageCount: 0,
      src: null,
      zoom: 150,
      loading: true
    }
  },
  computed: {
    noPrevPage () {
      return this.currentPage <= 1
    },
    noNextPage () {
      return this.currentPage === this.pageCount
    }
  },
  methods: {
    handlePageLoaded ($event) {
      this.loading = false
      this.currentPage = $event
    },
    prevPage () {
      this.loading = true
      window.scrollTo({ top: 0, behavior: 'smooth' })
      this.currentPage--
    },
    nextPage () {
      this.loading = true
      window.scrollTo({ top: 0, behavior: 'smooth' })
      this.currentPage++
    }
  }
}
</script>

<style lang="scss" scoped>
.pdf-viewer-wrapper.zoom-active {
  cursor: grab;
}
.pdf-viewer-wrapper {
  overflow: hidden;
  margin-bottom: 72px;
}
.bottom-pdf-actions {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: white;
  box-shadow: 1px -1px 9px 1px rgb(128 128 128 / 36%);
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    > div {
      flex: 1;
    }
    .current-page {
      text-align: left;
      max-width: fit-content;
    }
    .buttons {
      text-align: right;
      .btn {
        margin-left: 10px;
      }
    }
  }
}
.pinch-zoom-content span {
  background: white;
}
</style>
