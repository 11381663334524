<template>
  <div class="header">
    <div class="container">
      <slot name="left"></slot>
      <div class="title">{{ title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'header',
  props: ['title']
}
</script>
