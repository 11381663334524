<template>
  <div id="app">
    <router-view></router-view>
    <div class="footer">
      <div>
        © 2021 ONCLICK SOLUCIONS, S.L. <span>·</span>
        <router-link
          to="/privacy-policy"
          target="_blank"
        >Política de privacitat</router-link><span>·</span>
        <router-link
          to="/signature-policy"
          target="_blank"
        >Condicions de signatura
        </router-link>
      </div>
      <img class="logo" src="/img/logo.svg" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style lang="scss">
@import "assets/scss/global";

.footer {
  color: #a0a0a0;
  margin: 40px 20px;
  margin-bottom: 100px;
  FONT-SIZE: 13px;
  span {
    color: #cacaca;
    padding: 0 12px;
  }
}
.logo {
  width: 150px;
  margin-top: 40px;
}
</style>
