<template>
  <div>
    <Header :title="'Signatura de documents'"></Header>
    <div class="container" v-loading="loading">
      <div class="box">
        <div class="nif-form-group">
          <label>Introdueix el teu NIF</label>
          <input type="text" v-model="id_doc" placeholder="Ex. 12345678Z">
          <div class="error-msg" v-if="errorMsg">{{ errorMsg }}</div>
        </div>
        <div>
          <button class="btn btn-primary" @click="verifyId">Continuar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import btc from '@/mixins/base-tenant-component'
import Header from '@/components/includes/Header'

export default {
  name: 'login',
  mixins: [btc],
  components: {
    Header
  },
  data () {
    return {
      id_doc: null,
      loading: false,
      errorMsg: null
    }
  },
  methods: {
    showError (message) {
      this.errorMsg = message
      console.log(message)
    },
    verifyId () {
      this.loading = true
      axios.post(this.tenantUrl('/users/login-using-uuid'), {
        id_doc: this.id_doc,
        uuid: this.$route.params.uuid
      }).then((resp) => {
        sessionStorage.setItem('token', resp.data.token)
        this.$router.push({ name: 'Sign', params: { tenant: this.$route.params.tenant, uuid: this.$route.params.uuid } })
      }).catch((e) => {
        if (e.response.status === 404) {
          this.showError('No existeix cap firma pendent')
        } else if (e.response.status === 422) {
          this.showError(e.response.data.errors.id_doc[0])
        } else if (e.response.status === 401) {
          this.showError(e.response.data.error_message)
        } else {
          this.showError('S\'ha produït un error. Prova-ho més tard')
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.nif-form-group {
  max-width: 200px;
  margin: 0 auto;
  padding-top: 15px;
  text-align: center;
  label {
    font-size: 19px;
    color: #3c4263;
    padding-bottom: 10px;
    display: block;
    font-weight: bold;
    text-align: center;
  }
  input {
    text-align: center;
  }
}
button {
  margin: 15px 0;
}
.error-msg {
  color: #de2f2f;
  margin-top: 10px;
  font-size: 14px;
}
</style>
