import Vue from 'vue'
import Router from 'vue-router'
import Hello from '@/components/Hello'
import Login from '@/components/Login'
import Sign from '@/components/Sign'
import Confirmation from '@/components/Confirmation'
import PrivacyPolicy from '@/components/PrivacyPolicy'
import SignaturePolicy from '@/components/SignaturePolicy'
import Verify from '@/components/Verify'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Hello',
      component: Hello
    },
    {
      path: '/verify',
      name: 'Verify',
      component: Verify
    },
    {
      path: '/:tenant/:uuid',
      name: 'Login',
      component: Login
    },
    {
      path: '/:tenant/:uuid/sign',
      name: 'Sign',
      component: Sign
    },
    {
      path: '/:tenant/:uuid/confirmation',
      name: 'Confirmation',
      component: Confirmation
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicy',
      component: PrivacyPolicy
    },
    {
      path: '/signature-policy',
      name: 'SignaturePolicy',
      component: SignaturePolicy
    }
  ]
})
