import Vue from 'vue'
import App from './App.vue'
import router from './router'
import interceptorsSetup from './interceptors'
import './registerServiceWorker'
import ElementUI from 'element-ui'
import './assets/lib/element-ui/theme/index.css'

Vue.config.productionTip = false

interceptorsSetup()

Vue.use(ElementUI)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
